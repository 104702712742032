import { IonAlert, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonList, IonMenu, IonMenuButton, IonMenuToggle, IonTitle, IonToolbar,IonImg } from "@ionic/react";
import React, { useState } from "react";
import { ellipsisVerticalOutline, menu, qrCodeOutline } from "ionicons/icons"
import { menuController } from '@ionic/core'

const HomeToolbar: React.FC = () => {
    const [showQrAlert, setShowQrAlert] = useState(false)

    function openMenu() {
        menuController.open("help")
    }

    return (
        <div>

            <IonAlert
                isOpen={showQrAlert}
                onDidDismiss={() => setShowQrAlert(false)}
                header={'Research Horizons'}
                message={'QR Code scanner is currently disabled.'}
                buttons={['OK']}
            />
            <IonToolbar>

                <IonButtons slot="start">
                    {/* <IonMenuButton autoHide={false}></IonMenuButton> */}
                </IonButtons>
{/*                 <IonTitle>Glenn Space Technology Symposium</IonTitle> */}
                <IonImg src="https://appwrite-dev.nicholasdwest.com/v1/storage/buckets/669179b878d7650792f1/files/669179cf7554f9f84946/view?project=65e5ebea53ab9b72ac43&mode=admin"></IonImg>

                <IonButtons slot="start" >
                    <IonMenuButton autoHide={false} menu="help" onClick={openMenu}></IonMenuButton>
                </IonButtons>
            </IonToolbar>
        </div>
    )
}

export default HomeToolbar;
