import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonImg, IonItem, IonLabel, IonModal, IonPage, IonText, IonTitle, IonToolbar, useIonViewWillEnter } from "@ionic/react";
import Axios from "axios";
import firebase from "firebase";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import Presenter from "../components/PresenterInterface";
import ScoreRubricMenu from "../components/ScoreRubricMenu";
import PointCardList from "./PointCardList";
import JudgeInterface from "../components/JudgeInterface"
import { config } from "../config";
import { menuController } from '@ionic/core'
import { Storage } from '@ionic/storage';

const QUESTIONS = [
    "Content and Coherence",
    "Visual Presentation",
    "Mechanics",
    "Originality, Creativity",
    "Oral Presentation and Response to Questions",
    "Relevance to Advancing Space Initiatives or Practical Applications of Space Technologies"
]

const RUBRICS = [
    ["Content is accurate and presented in a logical order.", `Content is accurate but some information is missing and/or not presented in a logical order, but is still generally easy to follow.`, `Content is accurate but some information is missing and/or not presented in a logical order, making it difficult to follow.`, "Content is questionable. Information is not presented in a logical order, making it difficult to follow.", "Content is inaccurate. Information is not presented in a logical order, making it difficult to follow."],
    [`All figures, tables and images are appropriate, accurate and legible. Color / layout / style create a superior overall presentation.`,
        `Most of the figures, tables and images are appropriate, accurate and legible. Color/layout/style create an interesting overall presentation.`,
        `Figures, tables and images do not support the research well. Color/layout/style create a fair overall presentation.`,
        `Figures, tables and images are inappropriate, inaccurate and/or illegible. Color/layout/style create a confusing presentation.`,
        `Figures, tables and images are not used or do not relate to text, are inappropriate, inaccurate and/or illegible. Color/layout/style create a negative presentation.`],
    [`No spelling errors. No grammar errors. Text seems to be original work of author.`,
        `No spelling errors. Few grammar errors. Text seems to be original work of author.`,
        `No spelling errors. minor grammar errors. Some awkward wording. Text seems to be original work of author.`,
        `Minor spelling errors. Some grammar errors. Not clear if text is original work of author.`,
        `Many spelling and or grammar errors. Most text seems to be copied from another source.`],
    [`Excellent original thinking or creative innovation or technique. Strong contribution to discipline.`,
        `Good original thinking and creativity. Good contribution to discipline.`,
        `Some original thinking and creativity. Fair contribution to discipline.`,
        `Minimal original thinking and creativity. Questionable contribution to discipline`,
        `No originality or creativity. Not a contribution to discipline.`],
    [`Student holds attention of audiences with the use of direct eye contact. Strong, clear voice. Enthusiastic about the topic during the entire presentation. Demonstrates full knowledge and answers questions with appropriate elaboration. General audience can get a very clear understanding of the research.`,
        `Uses direct eye contact with audience, but still reads from poster. Minor mistakes, but quickly recovers. Student presents information in a logical sequence. Presentation raises understanding and awareness of most points. General audience can get a good understanding of the research.`,
        `Student is inconsistent between strong and weak eye contact, volume of voice, level of enthusiasm, etc. Seems unsure of explanation of research and backtracks occasionally to add something out of sequence. General audience can get a fair understanding of the research.`,
        `Minimal eye contact. Mostly reads from poster. Little movement or descriptive gestures. Shows only mild interest in topic. Voice is low, and difficult to hear. Student is uncomfortable and only answers simple questions. Confusing organization. Main points are unclear, weak or not well developed.`,
        `Student makes no eye contact with audience. No movement or descriptive gestures. Very nervous. Unable to answer questions. No enthusiasm. Voice is too soft making it very difficult to hear. Does not have a grasp of the information. Cannot explain main points. General audience is unable to understand significance of research or how it relates to the field.`],
    [`Topic and research are highly relevant to the advancement of space initiatives and/or applications of space technologies.`,
        `Topic and research are relevant to the advancement of space initiatives and/or applications of space technologies.`,
        `Topic and research are somewhat relevant to the advancement of space initiatives and/or applications of space technologies.`,
        `The topic and research are not very relevant to the advancement of space initiatives and/or applications of space technologies.`,
        `Topic and research are not relevant to the advancement of space initiatives and/or applications of space technologies.`]

]

interface SubmissionPageProps extends RouteComponentProps<{
    id: string;
}> { }
const SubmissionPage: React.FC<SubmissionPageProps> = ({ match }) => {
    const [presenter, setPresenter] = useState<Presenter>({ id: "0", department: "NA", email: "NA", firstName: "Loading...", lastName: "Loading...", projectTitle: "Loading...", university: "Loading...", presentationType: "NA", flight: "NA", poster: "", abstract: "" })
    const [judge, setJudge] = useState<JudgeInterface | undefined>(undefined)
    const [showAbstractModal, setShowAbstractModal] = useState(false)
    const [showPosterModel, setShowPosterModal] = useState(false)


    async function fetchJudge(email: string) {
        const store = new Storage({ name: "__auth" })
        await store.create()
        const user = await firebase.auth().currentUser

        Axios.get(`${process.env.REACT_APP_STRAPI_SERVER}/research-judges?email=${email}`, { headers: { Authorization: `Bearer ${await user?.getIdToken()}` } }).then((res) => {
            let data: any = res.data!;

            let currentJudge: JudgeInterface = {
                id: data[0].id,
                email: data[0].email,
                event_code: data[0].event_code,
                first_name: data[0].first_name,
                last_name: data[0].last_name,
                flight: data[0].flight,
                university: data[0].university,
                device_token: data[0].device_token,
                is_anonymous: false
            }
            setJudge(currentJudge)


        }).catch((err) => {
            console.log(err);
            alert('Error getting judge info.')
        })
    }

    async function fetchPresenter(id: string) {
        const store = new Storage({ name: "__auth" })
        await store.create()
        const user = await firebase.auth().currentUser

        Axios.get(`${process.env.REACT_APP_STRAPI_SERVER}/research-presenters/${id}`, { headers: { Authorization: `Bearer ${await user?.getIdToken()}` } }).then((response) => {
            let data: any = response.data;

            let newPresenter: Presenter = {
                id: data.id,
                department: data.department,
                email: data.email,
                firstName: data.first_name,
                lastName: data.last_name,
                projectTitle: data.project_title,
                university: data.university,
                presentationType: data.presentation_type,
                flight: data.flight,
                poster: data.poster_full !== undefined ? data.poster_full.url : "",
                abstract: data.abstract
            }

            setPresenter(newPresenter)
        })
    }

    useIonViewWillEnter(async () => {

        try {
            const user = await firebase.auth().currentUser



            if (user != null) {
                const store = new Storage({ name: "__auth" })
                await store.create()

                Axios.defaults.headers.common['Authorization'] = `Bearer ${await firebase.auth().currentUser?.getIdToken()}`
                if (user.isAnonymous) {
                    fetchPresenter(match.params.id)
                } else {
                    fetchJudge(user.email!)
                    fetchPresenter(match.params.id)
                }

            }

        } catch (error) {

        }

        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {

                const store = new Storage({ name: '__auth' })
                await store.create()
                if (await user.getIdToken() != null) {
                    store.set("token", await user.getIdToken())
                }
                try {
                    // const user = await firebase.auth().currentUser

                    if (user.isAnonymous) {
                        fetchJudge(`${user.uid}@conferenceio.com`)

                        fetchPresenter(match.params.id)
                    } else {
                        fetchJudge(user.email!)
                        fetchPresenter(match.params.id)
                    }

                } catch (error) {

                }
            }

            else {
                // alert(`Hello`)
            }
        })


        // menuController.enable()

    })

    return (
        <IonPage id="submission">
            <IonModal isOpen={showAbstractModal}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Abstract</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => setShowAbstractModal(false)}>Back</IonButton>
                        </IonButtons>

                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    <IonLabel class="ion-margin-top">
                        {presenter.abstract === undefined && <div>"Abstract Not Submitted"</div>}
                        {presenter.abstract !== undefined && <IonItem className="ion-padding">{presenter.abstract}</IonItem>}
                    </IonLabel>
                </IonContent>

            </IonModal>
            <IonModal isOpen={showPosterModel}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Poster</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => setShowPosterModal(false)}>Back</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>

                    {presenter.poster === "" ? <IonText>Poster Not Submitted</IonText> : <IonImg src={`${process.env.REACT_APP_STRAPI_SERVER}${presenter.poster}`}></IonImg>}


                </IonContent>

            </IonModal>

            {QUESTIONS.map((val, index) => {
                return (
                    <ScoreRubricMenu key={index} index={index} rubrics={RUBRICS[index]}></ScoreRubricMenu>

                )
            })}
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        {firebase.auth().currentUser?.isAnonymous && <IonBackButton defaultHref="/feedback/home" />}
                        {!firebase.auth().currentUser?.isAnonymous && <IonBackButton defaultHref="/home" />}

                    </IonButtons>
                </IonToolbar>

                <IonToolbar>
                    {/* <IonMenuButton autoHide={false}></IonMenuButton>s */}
                    <IonText class="ion-text-center ion-padding-top">
                        <h1>{presenter.firstName} {presenter.lastName}</h1>
                    </IonText>
                    <IonText class="ion-text-center ion-padding-top">
                        <h4>{presenter.university}</h4>
                    </IonText>
                    <IonText class="ion-text-center ion-padding-top">
                        <h5>{presenter.projectTitle}</h5>
                    </IonText>
                    {/* <IonItem> */}
                    <IonButton expand="block" onClick={() => setShowAbstractModal(true)}>Abstract</IonButton>
                    <IonButton expand="block" onClick={() => setShowPosterModal(true)}>Poster</IonButton>
                    {/* </IonItem> */}
                </IonToolbar>
            </IonHeader>
            <IonContent class="ion-padding-top">


                <PointCardList questions={QUESTIONS} judge={judge} presenter={match.params.id}></PointCardList>


            </IonContent>
        </IonPage>
    )

}

export default SubmissionPage;

function useIonModal(Body: any, arg1: { count: any; onDismiss: any; onIncrement: any; }): [any, any] {
    throw new Error("Function not implemented.");
}
