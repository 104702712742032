import { IonPage, IonHeader, IonContent, IonSegment, IonSegmentButton, IonLabel, isPlatform, IonGrid, IonRow, IonCol, IonMenu, IonToolbar, IonTitle, IonRadioGroup, IonItem, IonMenuToggle, IonButton, IonRadio } from "@ionic/react";
import React from "react";
import HomeToolbar from "../pages/HomeToolbar";
import FilterMenu from "./FilterFab";
import HomeMenu from "./HomeMenu";
import PresenterCard from "./PresenterCard";
import Presenter from "./PresenterInterface";

const PresenterCardList: React.FC<{ segment: string, presenters: Presenter[], results: any, setSegment: any, fetchPresenters: any, judge: any }> = ({ segment, presenters, results, setSegment, fetchPresenters, judge }) => {

  return (
    isPlatform('tablet') || isPlatform('desktop') ?
      ((segment === 'poster' ?
        (
          <IonPage id="home">
            <IonMenu contentId="home" menuId="departments-content" side="start">
              <IonHeader>
                <IonToolbar>
                  <IonTitle>Departments</IonTitle>
                </IonToolbar>
              </IonHeader>
              <IonContent className="ion-padding">
                <IonRadioGroup>
                  <IonItem>
                    <IonLabel>Grapes</IonLabel>
                    <IonRadio slot="end" value="grapes"></IonRadio>
                  </IonItem>
                </IonRadioGroup>
                <IonMenuToggle>
                  <IonButton>Save</IonButton>
                </IonMenuToggle>
              </IonContent>
            </IonMenu>
            <HomeMenu judge={judge}></HomeMenu>
            <IonHeader>
              <HomeToolbar></HomeToolbar>
            </IonHeader>
            <IonContent>

              <IonSegment onIonChange={e => {
                setSegment(e.detail.value!)
                fetchPresenters(e.detail.value!, judge.flight)
              }} value={segment}>
                <IonSegmentButton value="poster">
                  <IonLabel>Poster Sessions</IonLabel>

                </IonSegmentButton>
                <IonSegmentButton value="podium" disabled>
                  <IonLabel>Podium Sessions</IonLabel>
                </IonSegmentButton>
              </IonSegment>
              <IonGrid>
                <IonRow>
                  {presenters.map((data, index) => {
                    return (
                      <IonCol>
                        <PresenterCard key={index} data={data} results={results} judge={judge}></PresenterCard>
                      </IonCol>

                    )
                  })}
                </IonRow>
              </IonGrid>


            </IonContent>
          </IonPage>
        ) : (
          <IonPage>
            <IonMenu contentId="home" menuId="departments-content" side="start">
              <IonHeader>
                <IonToolbar>
                  <IonTitle>Departments</IonTitle>
                </IonToolbar>
              </IonHeader>
              <IonContent className="ion-padding">
                <IonRadioGroup>
                  <IonItem>
                    <IonLabel>Grapes</IonLabel>
                    <IonRadio slot="end" value="grapes"></IonRadio>
                  </IonItem>
                </IonRadioGroup>
                <IonMenuToggle>
                  <IonButton>Save</IonButton>
                </IonMenuToggle>
              </IonContent>
            </IonMenu>
            <HomeMenu judge={judge}></HomeMenu>
            <IonHeader>
              <HomeToolbar></HomeToolbar>
            </IonHeader>
            <IonContent>
              <IonSegment onIonChange={e => {
                setSegment(e.detail.value!)
                fetchPresenters(e.detail.value!, judge.flight)
              }} value={segment}>

                <IonSegmentButton value="poster">
                  <IonLabel>Poster Sessions</IonLabel>

                </IonSegmentButton>
                <IonSegmentButton value="podium" disabled>
                  <IonLabel>Podium Sessions</IonLabel>
                </IonSegmentButton>
              </IonSegment>
              <IonGrid>
                <IonRow>
                  {presenters.map((data, index) => {
                    return (
                      <IonCol>
                        <PresenterCard key={index} data={data} results={results} judge={judge}></PresenterCard>
                      </IonCol>
                    )
                  })}
                </IonRow>
              </IonGrid>


            </IonContent>
          </IonPage>
        ))) : ((segment === 'poster' ? (
          <IonPage id="home">

            <HomeMenu judge={judge}></HomeMenu>
            <IonHeader>
              <HomeToolbar></HomeToolbar>
            </IonHeader>
            <IonContent>

              <IonSegment onIonChange={e => {
                setSegment(e.detail.value!)
                fetchPresenters(e.detail.value!, judge.flight)
              }} value={segment}>
                <IonSegmentButton value="poster">
                  <IonLabel>Poster Sessions</IonLabel>

                </IonSegmentButton>
                <IonSegmentButton value="podium" disabled>
                  <IonLabel>Podium Sessions</IonLabel>
                </IonSegmentButton>
              </IonSegment>
              <div>
                {presenters.map((data, index) => {
                  return (
                    <PresenterCard key={index} data={data} results={results} judge={judge}></PresenterCard>

                  )
                })}
              </div>


            </IonContent>
          </IonPage>
        ) :

          (
            <IonPage>
              <IonMenu contentId="home" menuId="departments-content" side="start">
                <IonHeader>
                  <IonToolbar>
                    <IonTitle>Departments</IonTitle>
                  </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                  <IonRadioGroup>
                    <IonItem>
                      <IonLabel>Grapes</IonLabel>
                      <IonRadio slot="end" value="grapes"></IonRadio>
                    </IonItem>
                  </IonRadioGroup>
                  <IonMenuToggle>
                    <IonButton>Save</IonButton>
                  </IonMenuToggle>
                </IonContent>
              </IonMenu>
              <HomeMenu judge={judge}></HomeMenu>
              <IonHeader>
                <HomeToolbar></HomeToolbar>
              </IonHeader>
              <IonContent>
                <IonSegment onIonChange={e => {
                  setSegment(e.detail.value!)
                  fetchPresenters(e.detail.value!, judge.flight)
                }} value={segment}>

                  <IonSegmentButton value="poster">
                    <IonLabel>Poster Sessions</IonLabel>

                  </IonSegmentButton>
                  <IonSegmentButton value="podium" disabled>
                    <IonLabel>Podium Sessions</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
                <div>
                  {presenters.map((data, index) => {
                    return (
                      <PresenterCard key={index} data={data} results={results} judge={judge}></PresenterCard>
                    )
                  })}
                </div>


              </IonContent>
            </IonPage>
          )))
  )

}

export default PresenterCardList;