import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonLabel, IonItem, IonButton, IonIcon, IonRange, IonButtons, isPlatform, useIonViewWillEnter } from "@ionic/react";
import { helpOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { menuController } from '@ionic/core'
import './PointCardSlider.css'
import ScoreRubricMenu from "./ScoreRubricMenu";
const PointCardSlider: React.FC<{ title: string, index: number, scores: number[], updatesScores: (index: number, score: number) => void }> = ({ title, index, scores, updatesScores }) => {
    // const [] = useState<boolean>(false)
    const [score, setScore] = useState<number>(0)
    const [val, setVal] = useState<string>("")

    function chooseSlider() {
        if (isPlatform("android")) {
            return "android-slider"
        }

        else if (isPlatform("ios")) {
            return "iphone-slider"
        }

        else {
            return ""
        }
    }
    function selectScore(option: number) {
        // console.log(option)
        // console.log(score)
        //   setVal(option)
        // if (option === 5) {
        //     setVal("Satisfactory")
        // }

        // else if (option === 4.5 || 4) {
        //     setVal("Excellent")
        // }

        // else if (option === 3.5 || 3) {
        //     setVal("Good")
        // }

        // else if (option === 2.5 || 2) {
        //     setVal("Satisfactory")
        // }

        // else {
        //     setVal("Unsatisfactory")
        // }

        updatesScores(index, option)


    }

    function convertCriteria(score: number): string {
        if (score === 5) {
            return "Excellent"
        }

        else if (score === 4.5 || score === 4) {
            return "Very Good"
        }

        else if (score === 3.5 || score === 3) {
            return "Good"
        }

        else if (score === 2.5 || score === 2) {
            return "Fair"
        }

        else {
            return "Poor"
        }

    }

    useIonViewWillEnter(() => {
        // console.log('use effect')
        // console.log(scores[index])
        setScore(scores[index])
    }, [index, scores, score, setVal])

    return (
        <IonCard id={`submission_${index}`}>
            {/* <ScoreRubricMenu></ScoreRubricMenu> */}
            {/* <ScoreRubricMenu index={index}></ScoreRubricMenu> */}

            <IonCardHeader>
                <IonCardTitle>
                    <IonItem lines="none">
                        <p>{index + 1}. {title}</p>
                        <IonButtons slot="end">
                            <IonButton onClick={() => {
                                menuController.enable(true, `rubric_${index}`)
                                menuController.open(`rubric_${index}`)
                            }}>
                                <IonIcon src={helpOutline}></IonIcon>
                            </IonButton>
                        </IonButtons>

                    </IonItem>
                </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonItem lines="none">
                    <IonRange className={`${chooseSlider()}`} name="test" min={0} max={5} step={0.5} snaps={true} color="secondary" onIonChange={(e) => {
                        setScore(e.detail.value as number)
                        selectScore(e.detail.value as number)
                    }}>
                        <IonLabel slot="start">0</IonLabel>
                        <IonLabel slot="end">5</IonLabel>
                    </IonRange>
                </IonItem>
                <IonItem lines="none" className="ion-justify-center">
                    <h2 className="ion-padding-left" slot="start">Score: {score} - {convertCriteria(score)}</h2>
                    <h2 className="ion-padding-left" slot="end"></h2>
                </IonItem>


            </IonCardContent>


        </IonCard>
    )

}

export default PointCardSlider;